import React from "react"
import {Helmet} from "react-helmet"
import Layout from "../../components/layout"
import Jumbotron from 'react-bootstrap/Jumbotron'
import Container from 'react-bootstrap/Container'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
//import Row from 'react-bootstrap/Row'
//import Col from 'react-bootstrap/Col'
import "../../styles/styles.scss"
import galleryStyles from "./gallery.module.scss"
import Logo from "../../components/logo"
import "intersection-observer"
import leftCorner from "../../images/decorative-corner-left.svg"

//Lightbox//
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import ConcatedOptions from "../../components/lightbox";
import "../../components/lightbox.module.scss";
//Radio Buttons//
import RadioButtonsGroup from "./checkbox"
import SecondRadioButtonsGroup from "./checkbox-second"

class GalleryPage extends React.Component {
  
render() {
  
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <html lang="pl" />
        <title>Galeria | Pensjonat U Wierzby Pod Lasem</title>
        <meta name="description" content="Galeria zdjęć Pensjonatu U Wierzby Pod Lasem."/>
        <link rel="canonical" href="https://www.uwierzby.pl/galeria/"/>
        <link rel="preconnect" href="https://fonts.gstatic.com"/>
        <link rel="preload" href="https://fonts.googleapis.com/css2?family=Charm&family=Cormorant+SC:wght@300&family=Quicksand&display=swap" as="style"></link>
      </Helmet>
      <Layout>
        <section className={`JumbotronSection ${galleryStyles.JumbotronSection___gallery}`}>
          <Jumbotron fluid className={`Jumbotron`}>
            <div className={`Jumbotron_overlay`}></div>
            <div className={`Jumbotron_logo`}>
              <Logo/>
            </div>
            <Container fluid className={`Jumbotron_container`}>
              <h1 className="animate__animated animate__slideInLeft">galeria</h1>
              <h3 className={`SectionSubTitleH3 light animate__animated animate__slideInRight`}>pensjonat w obiektywie</h3>
            </Container>
          </Jumbotron>
        </section>
        <section className={`BreadcrumbSection`}>
          <Breadcrumb className={`Breadcrumb`}>
              <Breadcrumb.Item className={`Breadcrumb_item`} href="../">Strona Główna</Breadcrumb.Item>
              <Breadcrumb.Item className={`Breadcrumb_item`} active>Galeria</Breadcrumb.Item>
          </Breadcrumb>
        </section>
        <section className={`MainSection`}>
          <Container fluid className={`MainSection_wrapper`}>
            <img className={`MainSection_corner___left`} src={leftCorner} alt="top left decorative corner"></img>
            <div className={`MainSection_textWrapper`}>
            <div className={`SectionTitle_wrapper`}>
              <div className={`SectionTitle_bigLetter`}><p>G</p></div>
              <h1 className={`SectionTitle`}>galeria</h1>
              <h3 className={`SectionSubTitleH3`}>pensjonat w obiektywie</h3>
            </div>
              <div className={`MainSection_main gallery`}>
              <RadioButtonsGroup/>
              <SimpleReactLightbox>
                <div className={`${galleryStyles.GallerySection_fotoGrid} fotoGrid`}>
                  <SRLWrapper options={ConcatedOptions}>
                        <a className={`${galleryStyles.FotoGrid_item} all house`} 
                          href={require('../../images/galeria/main/pensjonat-glowne-photo01.jpg')} data-attribute="SRL" data-index="0">
                          <img className={`${galleryStyles.FotoGrid_itemImg} houseImg`} loading="lazy" src={require("../../images/galeria/thumb/pensjonat-glowne-photo01_thumb.jpg")} alt="Pensjonat U Wierzby Pod Lasem - widok z zewnątrz"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all house`} 
                            href={require('../../images/galeria/main/pensjonat-glowne-photo02.jpg')} data-attribute="SRL" data-index="1">
                            <img className={`${galleryStyles.FotoGrid_itemImg} houseImg`} loading="lazy" src={require("../../images/galeria/thumb/pensjonat-glowne-photo02_thumb.jpg")} alt="widok z zewnątrz"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all house`}
                            href={require('../../images/galeria/main/tabliczka.jpg')} data-attribute="SRL" data-index="2">
                            <img className={`${galleryStyles.FotoGrid_itemImg} houseImg`} loading="lazy" src={require("../../images/galeria/thumb/tabliczka_thumb.jpg")} alt="Tabliczka Polskiej Federacji Turystyki Wiejskiej"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all house`}
                            href={require('../../images/galeria/main/wejscie.jpg')} data-attribute="SRL" data-index="3">
                            <img className={`${galleryStyles.FotoGrid_itemImg} houseImg`} loading="lazy" src={require("../../images/galeria/thumb/wejscie_thumb.jpg")} alt="brama wjazdowa na teren agroturystyki"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all house`}
                            href={require('../../images/galeria/main/kuchnia-photo01.jpg')} data-attribute="SRL"
                            data-index="4">
                            <img className={`${galleryStyles.FotoGrid_itemImg} houseImg`} loading="lazy" src={require("../../images/galeria/thumb/kuchnia-photo01_thumb.jpg")} alt="ogólnodostępna kuchnia"></img>
                        </a>
                        <a  className={`${galleryStyles.FotoGrid_item} all house`}
                          href={require('../../images/galeria/main/kuchnia-photo02.jpg')} data-attribute="SRL"
                          data-index="5">
                          <img className={`${galleryStyles.FotoGrid_itemImg} houseImg`} loading="lazy" src={require("../../images/galeria/thumb/kuchnia-photo02_thumb.jpg")} alt="ogólnodostępna kuchnia"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all house`}
                            href={require('../../images/galeria/main/kuchnia-photo03.jpg')} data-attribute="SRL"
                            data-index="6">
                            <img className={`${galleryStyles.FotoGrid_itemImg} houseImg`} loading="lazy" src={require("../../images/galeria/thumb/kuchnia-photo03_thumb.jpg")} alt="ogólnodostępna kuchnia"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all room`}
                            href={require('../../images/galeria/main/pokoj-nr1-photo06-nikon.jpg')} data-attribute="SRL"
                            data-index="7">
                            <img className={`${galleryStyles.FotoGrid_itemImg} roomImg`} loading="lazy" src={require("../../images/galeria/thumb/pokoj-nr1-photo06-nikon_thumb.jpg")} alt="pokój nr 1"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all room`}
                            href={require('../../images/galeria/main/pokoj-nr1-photo07-nikon.jpg')} data-attribute="SRL"
                            data-index="8">
                            <img className={`${galleryStyles.FotoGrid_itemImg} roomImg`} loading="lazy" src={require("../../images/galeria/thumb/pokoj-nr1-photo07-nikon_thumb.jpg")} alt="pokój nr 1"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all room`}
                            href={require('../../images/galeria/main/pokoj-nr6-photo03.jpg')} data-attribute="SRL"
                            data-index="9">
                            <img className={`${galleryStyles.FotoGrid_itemImg} roomImg`} loading="lazy" src={require("../../images/galeria/thumb/pokoj-nr6-photo03_thumb.jpg")} alt="pokój nr 2"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all room`}
                            href={require('../../images/galeria/main/pokoj-nr6-photo04.jpg')} data-attribute="SRL"
                            data-index="10">
                            <img className={`${galleryStyles.FotoGrid_itemImg} roomImg`} loading="lazy" src={require("../../images/galeria/thumb/pokoj-nr6-photo04_thumb.jpg")} alt="pokój nr 2"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all room`}
                            href={require('../../images/galeria/main/pokoj-nr3-photo01-nikon.jpg')} data-attribute="SRL"
                            data-index="11">
                            <img className={`${galleryStyles.FotoGrid_itemImg} roomImg`} loading="lazy" src={require("../../images/galeria/thumb/pokoj-nr3-photo01-nikon_thumb.jpg")} alt="pokój nr 3"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all room`}
                            href={require('../../images/galeria/main/pokoj-nr3-photo02-nikon.jpg')} data-attribute="SRL"
                            data-index="12">
                            <img className={`${galleryStyles.FotoGrid_itemImg} roomImg`} loading="lazy" src={require("../../images/galeria/thumb/pokoj-nr3-photo02-nikon_thumb.jpg")} alt="pokój nr 3"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all room`}
                            href={require('../../images/galeria/main/pokoj-nr4-photo01-nikon.jpg')} data-attribute="SRL"
                            data-index="13">
                            <img className={`${galleryStyles.FotoGrid_itemImg} roomImg`} loading="lazy" src={require("../../images/galeria/thumb/pokoj-nr4-photo01-nikon_thumb.jpg")} alt="pokój nr 4"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all room`}
                            href={require('../../images/galeria/main/pokoj-nr5-photo01-nikon.jpg')} data-attribute="SRL"
                            data-index="14">
                            <img className={`${galleryStyles.FotoGrid_itemImg} roomImg`} loading="lazy" src={require("../../images/galeria/thumb/pokoj-nr5-photo01-nikon_thumb.jpg")} alt="pokój nr 5"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all room`}
                            href={require('../../images/galeria/main/pokoj-nr5-photo04-nikon.jpg')} data-attribute="SRL"
                            data-index="15">
                            <img className={`${galleryStyles.FotoGrid_itemImg} roomImg`} loading="lazy" src={require("../../images/galeria/thumb/pokoj-nr5-photo04-nikon_thumb.jpg")} alt="pokój nr 5"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all room`}
                            href={require('../../images/galeria/main/pokoj-nr5-photo07-nikon.jpg')} data-attribute="SRL"
                            data-index="16">
                            <img className={`${galleryStyles.FotoGrid_itemImg} roomImg`} loading="lazy" src={require("../../images/galeria/thumb/pokoj-nr5-photo07-nikon_thumb.jpg")} alt="pokój nr 5"></img>
                        </a>
                        
                        <a className={`${galleryStyles.FotoGrid_item} all attraction`}
                            href={require('../../images/galeria/main/basen-lisc.jpg')} data-attribute="SRL"
                            data-index="17">
                            <img className={`${galleryStyles.FotoGrid_itemImg} attractionImg`} loading="lazy" src={require("../../images/galeria/thumb/basen-lisc_thumb.jpg")} alt="basen"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all attraction`}
                            href={require('../../images/galeria/main/basen-prosto.jpg')} data-attribute="SRL"
                            data-index="18">
                            <img className={`${galleryStyles.FotoGrid_itemImg} attractionImg`} loading="lazy" src={require("../../images/galeria/thumb/basen-prosto_thumb.jpg")} alt="basen"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all attraction`}
                            href={require('../../images/galeria/main/basen-woda.jpg')} data-attribute="SRL"
                            data-index="19">
                            <img className={`${galleryStyles.FotoGrid_itemImg} attractionImg`} loading="lazy" src={require("../../images/galeria/thumb/basen-woda_thumb.jpg")} alt="basen"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all attraction`}
                            href={require('../../images/galeria/main/lezaki-basen.jpg')} data-attribute="SRL"
                            data-index="20">
                            <img className={`${galleryStyles.FotoGrid_itemImg} attractionImg`} loading="lazy" src={require("../../images/galeria/thumb/lezaki-basen_thumb.jpg")} alt="basen"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all attraction`}
                            href={require('../../images/galeria/main/ogrod-wypoczynek.jpg')} data-attribute="SRL"
                            data-index="21">
                            <img className={`${galleryStyles.FotoGrid_itemImg} attractionImg`} loading="lazy" src={require("../../images/galeria/thumb/ogrod-wypoczynek_thumb.jpg")} alt="stolik w ogródku"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all attraction`}
                            href={require('../../images/galeria/main/staw.jpg')} data-attribute="SRL"
                            data-index="22">
                            <img className={`${galleryStyles.FotoGrid_itemImg} attractionImg`} loading="lazy" src={require("../../images/galeria/thumb/staw_thumb.jpg")} alt="staw"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all attraction`}
                            href={require('../../images/galeria/main/altana.jpg')} data-attribute="SRL"
                            data-index="23">
                            <img className={`${galleryStyles.FotoGrid_itemImg} attractionImg`} loading="lazy" src={require("../../images/galeria/thumb/altana_thumb.jpg")} alt="altana"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all attraction`}
                            href={require('../../images/galeria/main/altana-daleko.jpg')} data-attribute="SRL"
                            data-index="24">
                            <img className={`${galleryStyles.FotoGrid_itemImg} attractionImg`} loading="lazy" src={require("../../images/galeria/thumb/altana-daleko_thumb.jpg")} alt="altana"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all attraction`}
                            href={require('../../images/galeria/main/altana-jesienia.jpg')} data-attribute="SRL"
                            data-index="25">
                            <img className={`${galleryStyles.FotoGrid_itemImg} attractionImg`} loading="lazy" src={require("../../images/galeria/thumb/altana-jesienia_thumb.jpg")} alt="grill w altanie"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} all attraction`}
                            href={require('../../images/galeria/main/altana-wnetrze.jpg')} data-attribute="SRL"
                            data-index="26">
                            <img className={`${galleryStyles.FotoGrid_itemImg} attractionImg`} loading="lazy" src={require("../../images/galeria/thumb/altana-wnetrze_thumb.jpg")} alt="wypoczynek w altanie"></img>
                        </a>
                    </SRLWrapper>
                  </div>
                </SimpleReactLightbox>
                <div className="wrapper"></div>
                  <div>
                    <div className={`SectionTitle_wrapper`}>
                      <div className={`SectionTitle_bigLetter`}><p>C</p></div>
                      <h2 className={`SectionSubTitleH2`}>cztery pory roku</h2>
                    </div>
                  </div>
                <SecondRadioButtonsGroup/>
                <SimpleReactLightbox>
                <div className={`${galleryStyles.GallerySection_fotoGrid} fotoGridSec`}>
                  <SRLWrapper options={ConcatedOptions}>
                        <a className={`${galleryStyles.FotoGrid_item} allYear spring`} 
                          href={require('../../images/galeria/spring/pensjonat-wiosna.jpg')}
                          data-attribute="SRL" 
                          data-indx="0">
                          <img className={`${galleryStyles.FotoGrid_itemImg} springImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/pensjonat-wiosna_thumb.jpg")} alt="Pensjonat U Wierzby Pod Lasem - widok z zewnątrz wiosną"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear spring`} 
                            href={require('../../images/galeria/spring/wejscie.jpg')}
                            data-attribute="SRL"
                            data-indx="1">
                            <img className={`${galleryStyles.FotoGrid_itemImg} springImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/wejscie_thumb.jpg")} alt="wejście na teren agroturystyki"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear spring`}
                            href={require('../../images/galeria/spring/brama.jpg')}
                            data-attribute="SRL"
                            data-indx="2">
                            <img className={`${galleryStyles.FotoGrid_itemImg} springImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/brama_thumb.jpg")} alt="Brama z Tabliczką Polskiej Federacji Turystyki Wiejskiej"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear spring`}
                            href={require('../../images/galeria/spring/hustawki.jpg')}
                            data-attribute="SRL"
                            data-indx="3">
                            <img className={`${galleryStyles.FotoGrid_itemImg} springImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/hustawki_thumb.jpg")} alt="huśtawki dla dzieci"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear spring`}
                            href={require('../../images/galeria/spring/las.jpg')}
                            data-attribute="SRL"
                            data-indx="4">
                            <img className={`${galleryStyles.FotoGrid_itemImg} springImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/las_thumb.jpg")} alt="pobliski las wiosną"></img>
                        </a>
                        <a  className={`${galleryStyles.FotoGrid_item} allYear summer`}
                          href={require('../../images/galeria/summer/ogrod-wypoczynek.jpg')}
                          data-attribute="SRL"
                          data-indx="5">
                          <img className={`${galleryStyles.FotoGrid_itemImg} summerImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/ogrod-wypoczynek_thumb.jpg")} alt="wypoczynek w ogródku"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear summer`}
                            href={require('../../images/galeria/summer/ogrod-roslinnosc.jpg')}
                            data-attribute="SRL"
                            data-indx="6">
                            <img className={`${galleryStyles.FotoGrid_itemImg} summerImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/ogrod-roslinnosc_thumb.jpg")} alt="ukwiecony ogórdek latem"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear summer`}
                            href={require('../../images/galeria/summer/staw-krzeslo.jpg')}
                            data-attribute="SRL" 
                            data-indx="7">
                            <img className={`${galleryStyles.FotoGrid_itemImg} summerImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/staw-krzeslo_thumb.jpg")} alt="wypoczynek nad stawem"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear summer`}
                            href={require('../../images/galeria/summer/staw.jpg')} 
                            data-attribute="SRL"
                            data-indx="8">
                            <img className={`${galleryStyles.FotoGrid_itemImg} summerImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/staw_thumb.jpg")} alt="staw"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear summer`}
                            href={require('../../images/galeria/summer/staw-mostek.jpg')}
                            data-attribute="SRL"  
                            data-indx="9">
                            <img className={`${galleryStyles.FotoGrid_itemImg} summerImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/staw-mostek_thumb.jpg")} alt="mostek nad stawem"></img>
                        </a>
                        <a  className={`${galleryStyles.FotoGrid_item} allYear fall`}
                          href={require('../../images/galeria/fall/pensjonat-jesien.jpg')}
                          data-attribute="SRL" 
                          data-indx="10">
                          <img className={`${galleryStyles.FotoGrid_itemImg} fallImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/pensjonat-jesien_thumb.jpg")} alt="pensjonat jesienią"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear fall`}
                            href={require('../../images/galeria/fall/altana-jesien.jpg')} 
                            data-attribute="SRL"
                            data-indx="11">
                            <img className={`${galleryStyles.FotoGrid_itemImg} fallImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/altana-jesien_thumb.jpg")} alt="altana jesienią"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear fall`}
                            href={require('../../images/galeria/fall/altana-jesien-wypoczynek.jpg')}
                            data-attribute="SRL" 
                            data-indx="12">
                            <img className={`${galleryStyles.FotoGrid_itemImg} fallImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/altana-jesien-wypoczynek_thumb.jpg")} alt="wypoczynek w altanie"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear winter`}
                            href={require('../../images/galeria/winter/pensjonat-zima.jpg')}
                            data-attribute="SRL"
                            data-indx="13">
                            <img className={`${galleryStyles.FotoGrid_itemImg} winterImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/pensjonat-zima_thumb.jpg")} alt="pensjonat zimą"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear winter`}
                            href={require('../../images/galeria/winter/przed-pensjonatem-zima.jpg')} 
                            data-attribute="SRL"
                            data-indx="14">
                            <img className={`${galleryStyles.FotoGrid_itemImg} winterImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/przed-pensjonatem-zima_thumb.jpg")} alt="przed pensjonatem zimą"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear winter`}
                            href={require('../../images/galeria/winter/altana-zima.jpg')}
                            data-attribute="SRL" 
                            data-indx="15">
                            <img className={`${galleryStyles.FotoGrid_itemImg} winterImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/altana-zima_thumb.jpg")} alt="altana zimą"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear winter`}
                            href={require('../../images/galeria/winter/wjazd-zima.jpg')} 
                            data-attribute="SRL"
                            data-indx="16">
                            <img className={`${galleryStyles.FotoGrid_itemImg} winterImg`} loading="lazy" src={require("../../images/galeria/seasons-thumb/wjazd-zima_thumb.jpg")} alt="teren agroturystyki zimą"></img>
                        </a>
                        <a className={`${galleryStyles.FotoGrid_item} allYear winter`}
                            href={require('../../images/galeria/winter/brama-zima.jpg')}
                            data-attribute="SRL" 
                            data-indx="17">
                            <img className={`${galleryStyles.FotoGrid_itemImg} winterImg`} loading="lazy" src={require("../../images/galeria/winter/brama-zima.jpg")} alt="teren agroturystyki zimą"></img>
                        </a>
                      </SRLWrapper>
                    </div>
                  </SimpleReactLightbox>
                  <div className="wrappersec"></div>
              </div>
            </div>
        </Container>
      </section>
    </Layout>
  </>
  ) 
}
}
export default GalleryPage

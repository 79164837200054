import React from 'react';
import "../../styles/styles.scss";

class RadioButtonsGroup extends React.Component {


    replyClick = (e) => {
        let clickedId = e.currentTarget.getAttribute("data-id");
        let dv = document.querySelectorAll(".wrapper")[0];  
        let lightboxDiv = document.querySelector(".fotoGrid div");
        let clicked = document.getElementsByClassName(clickedId);
        
        for (let i=0; i < clicked.length; i+=1) {
                clicked[i].classList.remove("nonselected");
                clicked[i].setAttribute("data-attribute", "SRL");
                lightboxDiv.appendChild(clicked[i]);
        }      
        
        let children = lightboxDiv.getElementsByTagName('a');
        let listitems = [];
        for (let k = 0; k < children.length; k++) {
                listitems.push(children.item(k));
            }
            listitems.sort(function(a, b) {
                var compA = Number(a.getAttribute('data-index'));
                var compB = Number(b.getAttribute('data-index'));
                return ((compA < compB) ? -1 : (compA > compB) ? 1 : 0);
            });
        for (let m = 0; m < listitems.length; m++) {
            lightboxDiv.appendChild(listitems[m]);
        }

        const notClicked = document.querySelectorAll(`.all:not(.${clickedId})`);
        for (let i=0; i < notClicked.length; i+=1) {
            notClicked[i].classList.add("nonselected");
            notClicked[i].removeAttribute("data-attribute");
            dv.appendChild(notClicked[i]);
        }
        let radio = document.querySelector(`#${clickedId} .radio`);
        const notRadio = document.querySelectorAll(`.radioButton:not(#${clickedId}) .radio`);
        radio.style.background = "var(--secondary-color)";
        for (let j=0; j < notRadio.length; j+=1) {
            notRadio[j].style.background = "var(--primary-color)";
        }
}

render() {
        return (
            <form className={`selectionButtonsGroup`}>
                <div className="radioButton" id="all" data-id="all" role = "button" tabIndex='0' onKeyDown={(e) => this.replyClick(e)} onClick={(e) => this.replyClick(e)}>
                    <div className="radio" ></div>
                        <label htmlFor="all">
                            <span>Wszystkie</span>
                        </label>
                    
                </div>
                <div className="radioButton" id="house" data-id="house" role = "button" tabIndex='0' onKeyDown={(e) => this.replyClick(e)} onClick={(e) => this.replyClick(e)}>
                    <div className="radio" ></div>
                        <label htmlFor="house">
                            <span>Pensjonat</span>
                        </label>
                    
                </div>
                <div className="radioButton" id="room" data-id="room" role = "button" tabIndex='0' onKeyDown={(e) => this.replyClick(e)} onClick={(e) => this.replyClick(e)}>
                    <div className="radio" ></div>
                        <label htmlFor="room">
                            <span>Pokoje</span>
                        </label>
                    
                </div>
                <div className="radioButton" id="attraction" data-id="attraction" role = "button" tabIndex='0' onKeyDown={(e) => this.replyClick(e)} onClick={(e) => this.replyClick(e)}>
                    <div className="radio" ></div>
                        <label htmlFor="attraction">
                            <span>Atrakcje</span>
                        </label>
                    
                </div>
            </form>

        )
    }
}

export default RadioButtonsGroup;